'use strict';

const animationPage = () => {
  AOS.init({
    startEvent: 'DOMContentLoaded',
    easing: 'ease-in-out',
    duration: 1400,
    once: true,
  });
};

const share = () => {

  function bindShare(trigger, shareBlock) {
    $(trigger).on('click', function(e) {
      $(this).toggleClass('is-active');
      $(shareBlock).toggleClass('is-show');
    });

    $(document).on('click', 'body', function(e) {
      const isBtnShare = $(e.target).hasClass('share'),
            isShare = $(e.target).hasClass('btn-share');

      if(!isBtnShare && !isShare && $(trigger).hasClass('is-active')) {
        $(trigger).removeClass('is-active');
        $(shareBlock).removeClass('is-show');
      }
    });
  }

  bindShare('.btn-share', '.share');
};

const scrollTo = () => {

  function onClickElem(trigger, elem) {
    $(trigger).on('click', function(e) {
      e.preventDefault();
      $('html,body').animate({
        scrollTop: $(elem).offset().top,
      }, 'slow');
    });
  };

  onClickElem('.btn-scroll button', '.section--intro');
};

const shareSocial = (selector) => {
  $(selector).socialLikes({
    counters: false,
  });
};

const eventsGA = () => {
  $('.logo').on('click', function() {
    ga("send", "event", "Click", "Logo", "Logo_Click");
  });

  $('.product__desc .btn').on('click', function() {
    ga("send", "event", "Click", "More", "More_Click");
  });

  $('.text-link').on('click', function() {
    ga("send", "event", "Click", "Link", "Link_Click");
  });
};


$(function () {
  animationPage();
  scrollTo();
  share();
  shareSocial('.share');
  eventsGA();
});
